import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { useMediaQuery } from "react-responsive"
import { IoLogoInstagram } from "react-icons/io"

const Navigation = props => {
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` })
  const [opened, setOpened] = useState(false)
  const el = useRef()
  const navlist = useRef()
  const tl = useRef(
    gsap.timeline({ defaults: { ease: "power2.inOut" } }).reverse()
  )

  useEffect(() => {
    if (!isMobile) {
      gsap.to(navlist.current, { left: 0, ease: "power2", duration: 1 })
      gsap.to("li", {
        duration: 0.2,
        scale: 1,
        opacity: 1,
        ease: "power1.inOut",
      })
    }

    const q = gsap.utils.selector(el)

    gsap.set(q(".bar"), {
      transformOrigin: "50% 50%",
    })

    tl.current
      .to(
        q(".bar1"),
        {
          y: 8,
        },
        0
      )
      .to(
        q(".bar3"),
        {
          y: -8,
        },
        0
      )
      .set(q(".bar2"), {
        opacity: 0,
      })
      .addLabel("rotate")
      .to(
        q(".bar1"),
        {
          rotation: 45,
          ease: "back.out(2)",
        },
        "rotate"
      )
      .to(
        q(".bar3"),
        {
          rotation: -45,
          ease: "back.out(2)",
        },
        "rotate"
      )
      .to(
        q(".bar"),
        {
          scaleX: Math.SQRT2,
          duration: 0.5,
          ease: "power1.inOut",
        },
        0
      )
      .to("li", {
        duration: 0.52,
        scale: 1,
        opacity: 1,
        ease: "power1.inOut",

        stagger: {
          from: "bottom",
          amount: 1.5,
        },
      })

    if (isMobile) {
      if (opened) {
        tl.current.play()
        gsap.to(navlist.current, { left: 0, ease: "power2", duration: 1 })
      } else {
        tl.current.reverse(-1)
        gsap.to(navlist.current, {
          left: "-100%",
          ease: "power2",
          duration: 1,
        })
      }
    }
  }, [opened, isMobile])

  return (
    <div className="mb-10 menubreak:mb-0">
      <svg
        className="svg hamburger w-10 fixed top-2 right-2 z-50 menubreak:invisible"
        viewBox="0 0 24 24"
        ref={el}
        onClick={() => setOpened(!opened)}
      >
        <polyline
          className="bar bar1"
          style={{ stroke: "black" }}
          points="2,4 22,4"
        />
        <polyline
          className="bar bar2"
          style={{ stroke: "black" }}
          points="2,12 22,12"
        />
        <polyline
          className="bar bar3"
          style={{ stroke: "black" }}
          points="2,20 22,20"
        />
      </svg>

      <Link className="pt-16 block w-full" to="/">
        <img
          className="m-auto"
          width="400"
          src="/images/M-L_logo_black.svg"
          alt="Marie-Louise Sjögren"
        />
      </Link>
      <ul
        ref={navlist}
        className="mob-nav -left-full menubreak:-left-0 fixed menubreak:relative h-screen bg-white menubreak:h-auto top-0 w-screen z-40 menubreak:py-2 menubreak:border menubreak:border-black menubreak:border-r-0 menubreak:border-l-0 flex flex-col menubreak:flex-row align-middle menubreak:justify-around menubreak:w-3/5 menubreak:mt-8 menubreak:mb-16 m-auto pt-20 menubreak:pt-auto place-items-start p-10 menubreak:p-0 lg:px-10"
      >
        {/* <li className="my-2 transform opacity-0 menubreak:opacity-100 menubreak:transform-none menubreak:my-0 lg:ml-5">
          <Link
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
            to="/antiques"
          >
            Antiques
          </Link>
        </li> */}
        <li className="my-2 transform opacity-0 menubreak:opacity-100  menubreak:transform-none menubreak:my-0">
          <Link
            to="/interior-design"
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
          >
            Interior design
          </Link>
        </li>
        {/* <li className="my-2 transform opacity-0 menubreak:opacity-100 menubreak:transform-none menubreak:my-0">
          <Link
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
            to="/mls-collection"
          >
            MLS Collection
          </Link>
        </li> */}

        {/* <li className="my-2 transform opacity-0 menubreak:opacity-100  menubreak:transform-none menubreak:my-0">
          <Link
            to="/gallery"
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
          >
            The gallery
          </Link>
        </li> */}
        <li className="my-2 transform opacity-0 menubreak:opacity-100  menubreak:transform-none menubreak:my-0">
          <Link
            to="/about"
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
          >
            About
          </Link>
        </li>
        <li className="my-2 transform opacity-0 menubreak:opacity-100  menubreak:transform-none menubreak:my-0  lg:mr-5">
          <Link
            to="/contact"
            className="font-sansserif font-light text-4xl menubreak:text-base menubreak:font-futura menubreak:uppercase hover:line-through menu"
            activeClassName="line-through"
          >
            Contact
          </Link>
        </li>
        <li className="menubreak:hidden transform opacity-0 menubreak:opacity-100 menubreak:transform-none mt-10 leading-none flex items-center justify-center menu">
          <a href="https://www.instagram.com/marielouise_sjogren_design/">
            <IoLogoInstagram size={20} className="inline mr-1" />
            marielouise_sjogren_design
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Navigation
